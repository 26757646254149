import styled from "styled-components";

export const Wrapper = styled.div`
button{
    background-color: #F67263;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: none;
    margin-bottom: 10px;
    color: white;
    border-radius: 25px;
}
`
